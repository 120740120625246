import { useT } from "@kanpla/system";
import { Button, Spinner, message } from "@kanpla/ui";
import { useEffect, useState } from "react";
import { useContainer } from "unstated-next";
import LandingWrapper from "../components/LandingWrapper";
import { AppContext } from "../components/contextProvider";
import LoginContent from "../components/login/LoginContent";

const Login = () => {
  const t = useT();

  const { auth } = useContainer(AppContext);

  const isLoggedIn = Boolean(!auth?.loading && auth?.user?.uid);

  const [timedOut, setTimedOut] = useState(false);

  useEffect(() => {
    setTimeout(() => setTimedOut(true), 5000);
    return () => setTimedOut(false);
  }, []);

  return (
    <LandingWrapper>
      <div className="w-full text-center my-auto">
        {isLoggedIn ? (
          <div className="flex flex-col h-100 w-100 justify-center items-center text-primary-main">
            <Spinner useCurrentColor size="medium" />
            <div className="mt-8">
              {timedOut && (
                <>
                  <p className="text-text-secondary text-sm">
                    {t("Experiencing issues with login? Click here:")}
                  </p>
                  <Button
                    type="primary"
                    shape="solid"
                    className="mt-2 w-auto"
                    onClick={() => {
                      auth.signOut().catch((e) => message.error(e?.message));
                    }}
                    dataCy="btn-logout"
                  >
                    {t("Log out")}
                  </Button>
                </>
              )}
            </div>
          </div>
        ) : (
          <LoginContent />
        )}
      </div>
    </LandingWrapper>
  );
};

export default Login;
